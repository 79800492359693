import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/page-header"
import NavbarTop from "../components/navbar-top"

import AreasDePractica from "../sections/areas-de-practica"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Áreas de Práctica" />
      <NavbarTop />
      <PageHeader title="Áreas de Práctica"  bg={data.file.childImageSharp.fluid}/>
      <AreasDePractica />
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "areas-de-practica.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default IndexPage
