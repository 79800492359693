import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styles from "./areas-de-practica.module.scss"

export default props => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//areas-de-practica//" } }
        sort: { fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            excerpt
            html
            frontmatter {
              title
              subtitle
              icon
            }
            parent {
              ... on File {
                id
                name
              }
            }
          }
        }
      }
    }
  `)

  const areas = data.edges.map(({ node }) => ({
    slug: node.parent.name,
    html: node.html,
    ...node.frontmatter,
  }))

  const distanceToTop = el => Math.floor(el.getBoundingClientRect().top)
  if (typeof window !== "undefined") {
    window.addEventListener(
      "scroll",
      evt => {
        var currentHash = ""
        document.querySelectorAll(".hash-section").forEach(each => {
          var distance = distanceToTop(each)
          var hash = each.id
          if (distance < 50 && distance > 0 && currentHash != hash) {
            window.location.hash = hash
            currentHash = hash
            console.log(hash)
          }
        })
      },
      false
    )
    window.onhashchange = function() {
      let hash = window.location.hash

      document
        .querySelectorAll(".hash-link")
        .forEach(link => link.classList.remove(styles.active))
      document
        .querySelector('a[href="/areas-de-practica' + hash + '"]')
        .classList.add(styles.active)
    }
  }
  return (
    <section
      id="areas-de-practica"
      className="pt-5"
      style={{
        backgroundColor: "#fafafa",
      }}
    >
      <div className="container-xl">
        <div className="row justify-content-between">
          <div className="col-lg-4 d-none d-lg-block">
            <ul
              className="nav flex-column justify-content-start py-5"
              style={{
                position: "sticky",
                top: 50,
              }}
            >
              {areas.map(area => (
                <li className="nav-item d-flex flex-nowrap align-items-center">
                  <i
                    className={area.icon + " fa-2x col-3"}
                    style={{
                      color: "#2e75b6",
                    }}
                  ></i>
                  <a
                    className={
                      "col-9 hash-link nav-link text-primary " + styles.navLink
                    }
                    href={"/areas-de-practica#" + area.slug}
                  >
                    {area.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-7">
            {areas.map(area => (
              <div id={area.slug} className="hash-section py-5">
                <h2
                  style={{
                    backgroundColor: "#fafafa",
                    borderBottom: "1px solid #ececec",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  {" "}
                  {area.title}
                </h2>
                <div dangerouslySetInnerHTML={{ __html: area.html }} />
                <div className="d-flex justify-content-end">
                  <Link
                    to="/contacto/"
                    className="btn btn-primary mt-4 w-100 d-block d-sm-none"
                  >
                    Contacto
                    <i className="far fa-comment ml-3"></i>
                  </Link>
                  <Link
                    to="/contacto/"
                    className="btn btn-primary mt-4 d-none d-sm-block"
                  >
                    Contacto
                    <i className="far fa-comment ml-3"></i>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
